.VideoLogs {
  width: auto;
  height: auto;
  padding: 2rem;
  color: var(--color_white);
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);

  @mixin absolutePosition absolute, 5%, 10%, 5%, 10%;

  p {
    margin: 0.2rem 0;
  }

  @mixin tvDevice {
    font-size: 1.875rem;
  }
}
